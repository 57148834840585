<template>
  <div>
    <h1>About tryfree.gg</h1>
    <ul style="margin-left: 2em">
      <li>
        <router-link to="/about/privacy" class="text-dark-50 text-hover-white">Privacy Policy</router-link>
      </li>
      <li>
        <router-link to="/about/tos" class="text-dark-50 text-hover-white">Terms of service</router-link>
      </li>
      <li>
        <router-link to="/about/userdeletion" class="text-dark-50 text-hover-white">User data deletion</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
ul {
  margin-left: 2em;
}
</style>